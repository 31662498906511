
@font-face {
  font-family: 'OrlandoMagicAppFont';
  src: url('./assets/fonts/Reveal/PublicaSansRound-Bd.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'OrlandoMagicAppFont';
  src: url('./assets/fonts/Reveal/PublicaSansRound-BdIt.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'OrlandoMagicAppFont';
  src: url('./assets/fonts/Reveal/PublicaSansRound-Lt.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'OrlandoMagicAppFont';
  src: url('./assets/fonts/Reveal/PublicaSansRound-Md.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'OrlandoMagicAppFont';
  src: url('./assets/fonts/Reveal/PublicaSansRound-Rg.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OrlandoMagicAppFont';
  src: url('./assets/fonts/Reveal/PublicaSansRound-RgIt.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}


body {
  font-family: 'MyCustomFont', sans-serif;
}


body {
  margin: 0;
  font-family: 'OrlandoMagicAppFont', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




