.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

:root {background-color: #000;}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media (min-width: 768px) {
  .landing-template {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  }
  .landing-template > div {
      width: 48%; 
  }
  .full-width-centered.text {
    padding:0 10%;
   }
   .full-width-centered-large {width: 100%!important;}
}

@media (min-width: 1024px) {
  .landing-template > div {
      width: 31%; 
  }
}
.full-width-centered {
  width: 100%!important;
  text-align: center;
  display: block;
  padding: 10px
}


.full-width-centered-assets img {
  border-radius: 10px;
}
.custom-link {
  display: inline-block; 
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.landing-template {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background-color: #000;
}
iframe{border: none;}

h1.landing-headline {
  text-transform: uppercase;
  color: white;
  font-size: 15px;
  letter-spacing: 0.6px;
  padding:0px 10px;
  font-weight: bold;
  text-align: center;
}
p.landing-description {color:white; padding: 10px; text-align: center;}
@media only screen and (max-width: 600px) {
  .layoutStyle {
    padding: 10px;
  }
}

.checklist {
  padding: 10px;
  color:white;
}

.checklist-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(255 255 255 / 52%);
}
.checklist-header h1 {
 color:white;
 font-size: 15px;
 text-transform: uppercase;
 
}

.checklist-icon {
  margin-right: 10px;
  width: 25px;
}

.checklist-items {
  list-style-type: none;
  padding: 0;
}

.checklist-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.check-item-mark {
  margin-right: 10px;
}

.check-item-text {
color:white;
font-size: 14px;
}

.app-space {
  background-color: #2f2f2f;
  margin: 10px;
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat; }

 .app-space img  {
 border-radius: 10px;
}

a.custom-link {display: block;}
.ant-empty-normal .ant-empty-description {color:rgba(255, 255, 255, 0.337);}
.info-card {margin: 10px 15px;
  border-radius: 5px;
 min-height: 25px; padding: 5px 0; background-color: #004FAB; align-items: center;}
.info-message {margin-left: 20px; color: white;}
.info-tag {   border-radius: 16px;
  font-size: 12px;
  padding: 0 10px;
  position: relative;
  left: 5px;
  letter-spacing: 1px;
  line-height: 19px;
  height: 18px;
}
.completion-message {background-color: #004FAB; color:white; padding: 10px; border-radius: 5px;}
.swiper-slide {padding-bottom: 30px;}